<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row no-gutters>
      <v-col cols="12">
        <graph-settings
          :graphType="settings.simulationType"
          @update:graphType="settings.simulationType = $event"
          :showDevicesWithFunction="settings.showDevicesWithFunction"
          @update:showDevicesWithFunction="
            settings.showDevicesWithFunction = $event
          "
          :sticky="settings.sticky"
          @update:sticky="settings.sticky = $event"
        ></graph-settings>
      </v-col>
      <v-col cols="12" class="network-topology-graph-container pa-0 ma-0">
        <!-- Here we insert SVG -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import * as d3 from 'd3';
import debounce from 'lodash.debounce';
import GraphSettings from './GraphSettings.vue';
import translateWord from '@/components/shared/translations';
import { htmlEscape, isString, isObject } from '@/helpers';
import { deepClone } from '@/helpers';
import _get from 'lodash.get';

export default {
  props: {
    graphData: Object,
  },
  components: {
    GraphSettings,
  },
  data() {
    return {
      images: {
        access_point: require(`@/assets/networkTopologyIcons/access-point.svg`),
        l3_switch: require(`@/assets/networkTopologyIcons/l3-switch.svg`),
        l2_switch: require(`@/assets/networkTopologyIcons/l2-switch-alt.svg`),
        router: require(`@/assets/networkTopologyIcons/router.svg`),
        other: require(`@/assets/networkTopologyIcons/other.svg`),
      },
      svg: null,
      width: 800,
      height: 600,
      links: [],
      simulation: undefined,
      labelSimulation: undefined,
      settings: {
        simulationType: 'hierarchy-left-to-right',
        showDevicesWithFunction: [
          'access_point',
          'l3_switch',
          'l2_switch',
          'router',
          'other',
        ],
        sticky: true,
      },
      zoom: null,
      graphContainer: null,
      labels: { nodes: [], links: [] },
      container: null,
      resizeObserver: new ResizeObserver(
        debounce((entries, observer) => {
          for (const entry of entries) {
            // on resize logic specific to this component
            this.resizeRedraw(entry.target, entry.contentRect);
          }
        }, 300),
      ),
    };
  },
  watch: {
    'graphData'() {
      this.redraw();
    },
    'settings.simulationType'() {
      this.redraw();
    },
    'settings.sticky'() {
      this.clearFixedNodePositions();
    },
    'settings.showDevicesWithFunction'() {
      this.redraw();
    },
  },
  computed: {
    graphDataFiltered() {
      const filteredNodes = this.graphData.nodes.filter((node) => {
        return this.settings.showDevicesWithFunction.includes(node.function);
      });
      const nodes = filteredNodes.map((node) => {
        return node.label;
      });
      const filteredEdges = this.graphData.edges.filter((edge) => {
        return nodes.includes(edge.source) && nodes.includes(edge.target);
      });

      return deepClone({ nodes: filteredNodes, edges: filteredEdges });
    },
    // Create list adjacent nodes, this is used for highlighting connections to neighboring nodes
    adjlist() {
      const adjacentList = [];
      this.graphDataFiltered.edges.forEach((d) => {
        adjacentList[d.source.index + '-' + d.target.index] = true;
        adjacentList[d.target.index + '-' + d.source.index] = true;
      });
      return adjacentList;
    },
  },
  methods: {
    getNodeInfoForTable: function (node) {
      const data = [];
      const displayKeys = [
        'label',
        'type',
        'description',
        'function',
        'roles',
        'is_tsp',
        'managed',
        'failed',
      ];
      displayKeys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(node, key)) {
          let value = node[key];
          if (value === null) {
            value = '/';
          }
          if (!isString(value) && !Array.isArray(value)) {
            value = htmlEscape(
              translateWord(JSON.stringify(value), false, false),
            );
          } else if (!isString(value) && Array.isArray(value)) {
            value = value.map((element) => {
              if (element !== null) {
                return htmlEscape(translateWord(element, false, false));
              } else {
                return '/';
              }
            });
          } else {
            value = htmlEscape(translateWord(value, false, false));
          }
          data.push([htmlEscape(translateWord(key)), value]);
        }
      });
      for (const key of ['spanning_tree.protocol', 'spanning_tree.priority']) {
        const value = _get(node, key);
        if (value !== undefined) {
          data.push([
            translateWord(key.replace('spanning_tree.', 'STP ')),
            htmlEscape(String(value || '')),
          ]);
        }
      }
      for (const key of [
        'system.os_version',
        'system.os_version_valid',
        'system.hardware_model',
      ]) {
        const value = _get(node, key);
        if (value !== undefined) {
          data.push([
            translateWord(key?.replace('system.', '')),
            htmlEscape(value || ''),
          ]);
        }
      }
      return data;
    },
    getDeviceIcon: function (deviceFunction) {
      if (Object.prototype.hasOwnProperty.call(this.images, deviceFunction)) {
        return this.images[deviceFunction];
      } else {
        return this.images['other'];
      }
    },
    resizeRedraw(container, { width, height }) {
      this.width = width;
      this.height = height;

      if (this.graphDataFiltered) {
        this.redraw();
      }
    },
    redraw() {
      this.cleanSVG();
      this.createSVG();
      this.calculateLinksArches();
      this.setupForceSimulation();
      this.updateSVG();
    },
    cleanSVG() {
      // Remove everything below the SVG element
      d3.selectAll('svg.network-topology-force-graph > *').remove();
    },
    createSVG() {
      if (!this.graphContainer) {
        return;
      }
      // Get container width & height
      this.width = this.width ? this.width : 400;
      this.height = this.height ? this.height : 500;

      // Create data for labels graph
      this.labels = { nodes: [], links: [] };
      this.graphDataFiltered.nodes.forEach((d, i) => {
        this.labels.nodes.push({ node: d });
        this.labels.nodes.push({ node: d });
        this.labels.links.push({
          source: i * 2,
          target: i * 2 + 1,
        });
      });
      // Create svg container
      if (!this.svg) {
        this.svg = d3
          .select(this.graphContainer)
          .append('svg')
          .attr('class', 'network-topology-force-graph');
      }
      this.svg.attr('width', this.width).attr('height', this.height);

      this.container = this.svg.append('g').attr('id', '#container');
      this.zoom = d3
        .zoom()
        .scaleExtent([0.1, 4])
        .on('zoom', (event, d) => {
          this.container.attr('transform', event.transform);
          d3.selectAll('.labelNodes').style(
            'font-size',
            `${Math.max(0.7, 0.7 / event.transform.k)}em`,
          ); // Inverse scaling for dynamic font size
        });
      this.svg.call(this.zoom);
      this.tooltip = this.container
        .append('g')
        .classed('tooltipContainer', true);

      const markerTypes = ['FWD', 'BLK', 'DIS', 'LIS', 'LRN', 'NONE'];
      const markerColors = d3
        .scaleOrdinal([
          '#0000', // For FWD marker we use transparent color
          '#da1e28',
          '#ff832b',
          '#f1c21b',
          '#f1c21b',
          '#777',
        ])
        .domain(markerTypes);

      // STP markers for links
      // End marker
      this.container
        .append('defs')
        .selectAll('marker')
        .data(markerTypes)
        .join('marker')
        .attr('id', (d) => `circleMarkerEnd-${d}`) // Marker id
        .attr('viewBox', '0 -5 10 10') // View box for the marker
        .attr('refX', 20) // Adjust X reference point to position the marker
        .attr('refY', 0) // Adjust Y reference point
        .attr('markerWidth', 6) // Define marker width
        .attr('markerHeight', 6) // Define marker height
        .attr('orient', 'auto') // Automatically orient the marker
        .append('circle')
        .attr('r', 3)
        .attr('cx', 3)
        .attr('fill', markerColors);
      // Start marker
      this.container
        .append('defs')
        .selectAll('marker')
        .data(markerTypes)
        .join('marker')
        .attr('id', (d) => `circleMarkerStart-${d}`)
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', -13)
        .attr('refY', 0)
        .attr('markerWidth', 6)
        .attr('markerHeight', 6)
        .attr('orient', 'auto')
        .append('circle')
        .attr('r', 3)
        .attr('cx', 3)
        .attr('fill', markerColors);
    },
    calculateLinksArches() {
      this.links = this.graphDataFiltered.edges;
      this.links.forEach((link) => {
        // find other links with same target+source or source+target
        const same = this.links.filter((_link) => {
          return (
            (_link.source === link.source && _link.target === link.target) ||
            (_link.target === link.source && _link.source === link.target)
          );
        });

        same.forEach((s, i) => {
          s.sameIndex = i + 1;
          s.sameTotal = same.length;
          s.sameTotalHalf = s.sameTotal / 2;
          s.sameUneven = s.sameTotal % 2 !== 0;
          s.sameMiddleLink =
            s.sameUneven === true && Math.ceil(s.sameTotalHalf) === s.sameIndex;
          s.sameLowerHalf = s.sameIndex <= s.sameTotalHalf;
          s.sameArcDirection = s.sameLowerHalf ? 0 : 1;
          s.sameIndexCorrected = s.sameLowerHalf
            ? s.sameIndex
            : s.sameIndex - Math.ceil(s.sameTotalHalf);
        });
      });

      const maxSame = this.links.reduce(
        (prev, current) =>
          prev > current.sameTotal ? prev : current.sameTotal,
        1,
      );
      this.links.forEach((link) => {
        link.maxSameHalf = maxSame / 3; // Math.floor(maxSame / 3);
      });
    },
    neigh(a, b) {
      return a == b || this.adjlist[a + '-' + b];
    },
    clearFixedNodePositions() {
      if (this.container) {
        this.container
          .selectAll('image.network-topology-graph-node')
          .each((d) => {
            d.fx = null;
            d.fy = null;
          });
        if (!this.settings.sticky && this.simulation != null) {
          this.simulation.alpha(0.02).restart();
        }
      }
    },
    updateSVG() {
      const linkColors = [
        '#E6832A',
        '#D32F2F',
        '#E53935',
        '#F44336',
        '#EF5350',
        '#E57373',
        '#EF9A9A',
      ];
      const link = this.container
        .append('g')
        .classed('link', true)
        .selectAll('link')
        .data(this.links)
        .join(function (group) {
          const enter = group.append('g').attr('class', 'link');
          enter
            .append('path')
            .attr('stroke', (d) => {
              if (d.data.is_circuit) {
                return '#000';
              }
              if (!d.target.managed || !d.source.managed) {
                return '#969696';
              }
              let color = '';
              try {
                color = linkColors[d.sameIndex - 1];
              } catch (error) {
                color = linkColors[linkColors.length - 1];
              }
              return color;
            })
            .style('fill', 'none')
            .attr('stroke-opacity', 0.8)
            .attr('stroke-width', 2)
            .attr('marker-start', (d) => {
              // Get stp state from target interface
              const stpState = d.data?.source?.interface?.stp?.state || 'NONE';
              return `url(#circleMarkerStart-${stpState})`; // Apply marker start
            })
            .attr('marker-end', (d) => {
              // Get stp state from target interface
              const stpState = d.data?.target?.interface?.stp?.state || 'NONE';
              if (stpState != null) return `url(#circleMarkerEnd-${stpState})`; // Appl
            });

          enter
            .append('path')
            .attr('stroke', (d) => {
              if (d.data.is_circuit) {
                return '#000';
              }
              if (!d.target.managed || !d.source.managed) {
                return '#969696';
              }
              let color = '';
              try {
                color = linkColors[d.sameIndex - 1];
              } catch (error) {
                color = linkColors[linkColors.length - 1];
              }
              return color;
            })
            .style('fill', function (d, i) {
              return 'none';
            })
            .attr('stroke-opacity', 0.6)
            .attr('opacity', 0)
            .attr('stroke-width', 10)
            .classed('wideLink', true);

          return enter;
        });

      const node = this.container
        .append('g')
        .selectAll('g')
        .data(this.graphDataFiltered.nodes)
        .join('g')
        .append('image')
        .attr('xlink:href', (d) => {
          return this.getDeviceIcon(d.function);
        })
        .classed('network-topology-graph-node', true)
        .classed('unreachable', (d) => {
          return d.failed;
        })
        .attr('width', 24)
        .attr('height', 24)
        .attr('x', -12)
        .attr('y', -12);
      //   if (event.defaultPrevented) {
      //     return;
      //   } // dragged
      // });

      const labelNode = this.container
        .append('g')
        .attr('class', 'labelNodes')
        .selectAll('text')
        .data(this.labels.nodes)
        .enter()
        .append('text')
        .text(function (d, i) {
          return i % 2 == 0 ? '' : d.node.label.replace('.cpe.arnes.si', ''); // One node is below
        })
        .style('fill', '#111')
        .attr('stroke', '#fff9')
        .style('paint-order', 'stroke') // This changes the order of stroke and fill. Making stroke first and fill second. This helps improve visibility of labels over icons.
        .attr('stroke-width', 1.5)
        .style('font-size', '0.70em')
        .style('pointer-events', 'none'); // to prevent mouseover/drag capture

      function focus(event, d) {
        const index = d.index;
        node.style('opacity', (o) => {
          return this.neigh(index, o.index) ? 1 : 0.2;
        });
        labelNode.attr('display', (o) => {
          return this.neigh(index, o.node.index) ? 'block' : 'none';
        });
        link.style('opacity', (o) => {
          return o.source.index == index || o.target.index == index ? 1 : 0.2;
        });
      }

      function unfocus() {
        labelNode.attr('display', 'block');
        node.style('opacity', 1);
        link.style('opacity', 1);
      }

      d3.select('.network-topology-force-graph').on('click', (event, d) => {
        this.mouseleaveTip(event, d);
      });
      node
        .on('mouseover', (event, d) => {
          focus.bind(this)(event, d);
        })
        .on('mouseout', (event, d) => {
          unfocus.bind(this)(event, d);
        })
        .on('click', (event, d) => {
          event.stopPropagation();
          this.mouseoverTip(event, d);
        });

      // Do not use arrow functions if you want to select event source element because it will change this from d3 to vue
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      link
        .on('mouseover', function (event, d) {
          d3.select(this).select('.wideLink').attr('opacity', 1);
          const [x, y] = d3.pointer(event);
          vm.showLinkData(d.data, x, y);
        })
        .on('mouseout', function (event, d) {
          d3.select(this).select('.wideLink').attr('opacity', 0);
          vm.mouseleaveTip();
        })
        .on('click', (event, d) => {
          event.stopPropagation();
        });

      function fixna(x) {
        if (isFinite(x)) return x;
        return 0;
      }

      function updateNode(node) {
        node.attr('transform', function (d) {
          return 'translate(' + fixna(d.x) + ',' + fixna(d.y) + ')';
        });
      }
      this.simulation.on('tick', () => {
        node.call(updateNode);
        link.selectAll('path').attr('d', (d) => {
          const x1 = fixna(d.source.x);
          const y1 = fixna(d.source.y);
          let x2 = fixna(d.target.x);
          let y2 = fixna(d.target.y);
          const dx = x2 - x1;
          const dy = y2 - y1;
          const dr = Math.sqrt(dx * dx + dy * dy);
          const unevenCorrection = d.sameUneven ? 0 : 0.5;
          let arc =
            (dr * d.maxSameHalf) / (d.sameIndexCorrected - unevenCorrection);

          if (d.sameMiddleLink) {
            arc = 0;
          }

          let xRotation = 0;
          let largeArc = 0;
          let sweep = 0;

          // Self edge.
          if (d.target.x === d.source.x && d.target.y === d.source.y) {
            // Fiddle with this angle to get loop oriented.
            xRotation = -45;

            // Needs to be 1.
            largeArc = 1;

            // Change sweep to change orientation of loop.
            sweep = 0;

            // Make drx and dry different to get an ellipse
            // instead of a circle.
            arc = 30;
            arc = 20;

            // For whatever reason the arc collapses to a point if the beginning
            // and ending points of the arc are the same, so kludge it.
            x2 = x2 + 1;
            y2 = y2 + 1;
          }

          return (
            'M' +
            x1 +
            ',' +
            y1 +
            'A' +
            arc +
            ',' +
            arc +
            ' ' +
            xRotation +
            ',' +
            largeArc +
            ',' +
            d.sameArcDirection +
            ' ' +
            x2 +
            ',' +
            y2
          );
        });

        this.labelSimulation.alphaTarget(0.3).restart();
        labelNode.each(function (d, i) {
          if (i % 2 == 0) {
            d.x = d.node.x;
            d.y = d.node.y;
          } else {
            const b = this.getBBox();

            const diffX = d.x - d.node.x;
            const diffY = d.y - d.node.y;

            if (isNaN(diffX) || isNaN(diffY)) return;

            const dist = Math.sqrt(diffX * diffX + diffY * diffY);

            let shiftX = (b.width * (diffX - dist)) / (dist * 2);
            // shiftX = Math.max(-b.width, Math.min(0, shiftX));
            shiftX = Math.max(16, Math.min(0, shiftX));

            const shiftY = 12;

            this.setAttribute(
              'transform',
              'translate(' + shiftX + ',' + shiftY + ')',
            );
          }
        });

        labelNode.call(updateNode);
      });

      this.dragHandler(node, this.simulation);
    },
    dragHandler(dragContext, simulation) {
      // Drag functions
      const dragStart = (event, d) => {
        // remove device info modal
        d3.select('.fO').remove();

        /** Preventing propagation of dragstart to parent elements */
        event.sourceEvent.stopPropagation();

        if (!event.active) {
          simulation.alphaTarget(0.2).restart();
        }
        dragContext.classed('network-topology-cursor-grabbing', true);

        d.fx = d.x;
        d.fy = d.y;
      };

      // make sure you can't drag the circle outside the box
      const dragActions = (event, d) => {
        d.fx = event.x;
        d.fy = event.y;
      };

      const dragEnd = (event, d) => {
        if (!event.active) {
          simulation.alphaTarget(0);
        }
        dragContext.classed('network-topology-cursor-grabbing', false);
        if (!this.settings.sticky) {
          d.fx = null;
          d.fy = null;
        }
      };

      // apply drag handler
      dragContext.call(
        d3
          .drag()
          .on('start', dragStart)
          .on('drag', dragActions)
          .on('end', dragEnd),
      );
    },

    showLinkData(data, x, y) {
      this.mouseleaveTip();

      // Raise selection above all children of svg container
      d3.select('.tooltipContainer').attr(
        'transform',
        `translate(${5}, ${5})translate(${x}, ${y})`,
      );
      this.tooltip
        .append('foreignObject')

        .classed('fO', true)
        .attr('width', '100%')
        .attr('height', '100%')
        .append('xhtml:body')
        .classed('graphForeignObjectHTML', true);

      d3.select('g.tooltipContainer').raise();

      const ul = d3
        .select('.graphForeignObjectHTML')
        .append('div')
        .append('ul')
        .classed('network-topology-styled-ul-list', true);

      function makeUlList(parentDOM, linkData) {
        Object.entries(linkData).forEach(function ([key, value]) {
          const displayValueInNewUl = isObject(value) || Array.isArray(value);
          //add li element
          parentDOM
            .append('li')
            .text(
              `${translateWord(key)}${
                !displayValueInNewUl
                  ? ': ' +
                    translateWord(
                      value !== null ? value + '' : '/',
                      false,
                      false,
                      true,
                    )
                  : ''
              }`,
            );
          //if children then make ul
          if (displayValueInNewUl) {
            const ul = parentDOM.append('ul');
            //recurse pass ul as parentDOM
            makeUlList(ul, value);
          }
        });
      }

      makeUlList(ul, data);

      // resize foreignObject to fit content
      const bbox = d3.select('.network-topology-styled-ul-list').node();
      d3.select('.fO')
        .attr('width', bbox.offsetWidth + 'px')
        .attr('height', bbox.offsetHeight + 'px');
    },

    mouseleaveTip(event, d) {
      d3.select('.fO').remove();
    },

    mouseoverTip(event, d) {
      this.mouseleaveTip();
      // Raise selection above all children of svg container
      d3.select('.tooltipContainer').attr(
        'transform',
        `translate(${10}, ${10})translate(${d.x}, ${d.y})`,
      );
      this.tooltip
        .append('foreignObject')
        .classed('fO', true)
        .attr('width', '100%')
        .attr('height', '100%')
        .append('xhtml:body')
        .classed('graphForeignObjectHTML', true);

      d3.select('g.tooltipContainer').raise();

      const table = d3
        .select('.graphForeignObjectHTML')
        .append('table')
        .classed('network-topology-styled-table', true);
      const header = table.append('thead').append('tr');
      header
        .selectAll('th')
        .data(['Ime', 'Vrednost'])
        .enter()
        .append('th')
        .text(function (d) {
          return d;
        });
      const tablebody = table.append('tbody');
      const data = this.getNodeInfoForTable(d);
      const rows = tablebody.selectAll('tr').data(data).enter().append('tr');
      // We built the rows using the nested array - now each row has its own array.
      const cells = rows
        .selectAll('td')
        // each row has data associated; we get it and enter it for the cells.
        .data(function (d) {
          return d;
        })
        .enter()
        .append('td')
        .text(function (d) {
          return d;
        });

      const bbox = d3.select('.network-topology-styled-table').node();

      d3.select('.fO')
        .attr('width', bbox.offsetWidth + 'px')
        .attr('height', bbox.offsetHeight + 'px');
    },
    // mousemoveTip(event, d) {
    //   const text = d3.select('.tooltip_text');
    //   text.text(`${d.name}`);
    //   const [x, y] = d3.pointer(event);

    //   this.tooltip.attr('transform', `translate(${-x}, ${y})`);
    // },

    setupForceSimulation() {
      this.labelSimulation = d3
        .forceSimulation(this.labels.nodes)
        .force('charge', d3.forceManyBody().strength(-18).distanceMax(100))
        .force('link', d3.forceLink(this.labels.links).distance(10));
      // Reset previous forces
      if (this.simulation != null) {
        this.simulation
          .force('link', null)
          .force('charge', null)
          .force('center', null)
          .force('x', null)
          .force('y', null)
          .force('collision', null);
      }

      this.clearFixedNodePositions();

      // Set new forces
      if (this.settings.simulationType === 'free') {
        this.simulation = d3
          .forceSimulation(this.graphDataFiltered.nodes)
          // link force (pushes linked nodes together or apart according to the desired link distance):
          .force(
            'link',
            d3
              .forceLink(this.links)
              .id((d) => d.id)
              .distance(70)
              .strength(1),
          )
          // many-body force (force applied amongst all nodes, negative strength for repulsion):
          .force('charge', d3.forceManyBody().strength(-1500))
          .force('x', d3.forceX(this.width / 2).strength(0.4))
          .force('y', d3.forceY(this.height / 2).strength(0.4));
        // prevent nodes from ovelapping, treating them as circles with the given radius:
        // .force('collision', d3.forceCollide().radius(10));
      } else if (this.settings.simulationType === 'hierarchy-top-down') {
        this.simulation = d3
          .forceSimulation(this.graphDataFiltered.nodes)
          .force(
            'link',
            d3
              .forceLink(this.links)
              .id(function (d) {
                return d.id;
              })
              .distance(150)
              .strength(0.2),
          )
          .force('charge', d3.forceManyBody().strength(-700))
          .force('x', d3.forceX(this.width / 2).strength(0.05))
          .force(
            'y',
            d3
              .forceY((d) => {
                const part =
                  Math.max(1080, this.height * 1.5) / this.graphData.depth;
                return part * d.group;
              })
              .strength(0.6),
          )
          .force('collision', d3.forceCollide().radius(12));
        // centering force (mean position of all nodes):
        // .force('center', d3.forceCenter(this.width/2, this.height/2))
      } else if (this.settings.simulationType === 'hierarchy-left-to-right') {
        const xScale = d3
          .scalePow()
          .exponent(1)
          .domain([1, Math.max(5, this.graphData.depth + 1)])
          .range([50, Math.max(1920, this.width) - 50]); // Logarithmic scaling for x-position

        this.simulation = d3
          .forceSimulation(this.graphDataFiltered.nodes)
          .force('y', d3.forceY(this.height / 2))
          .force(
            'link',
            d3
              .forceLink(this.links)
              .id(function (d) {
                return d.id;
              })
              .distance((d) => {
                return 200;
              })
              .strength((d) => {
                const short_links = ['access_point', 'other'];
                if (
                  short_links.includes(d.source.function) ||
                  short_links.includes(d.target.function)
                ) {
                  return 0.8;
                } else {
                  return 0.05;
                }
              }),
          )
          .force(
            'charge',
            d3.forceManyBody().strength(-800).distanceMin(250), //.theta(0.7),
          )
          .force(
            'x',
            d3
              .forceX((d) => {
                return xScale(d.group + 1);
              })
              .strength((d) => {
                const short_links = ['access_point', 'other'];
                if (short_links.includes(d.function)) {
                  return 0.6;
                } else {
                  return 0.9;
                }
              }),
          )
          .force(
            'collision',
            d3
              .forceCollide()
              .radius((d) => (d.function === 'access_point' ? 25 : 40))
              .strength(0.5),
          );
      }
      this.simulation.alpha(1).restart();
    },
  },
  mounted() {
    this.graphContainer = document.querySelector(
      '.network-topology-graph-container',
    );
    this.resizeObserver.observe(this.graphContainer);
  },
  destroyed() {
    this.resizeObserver.disconnect();
  },
};
</script>
<style lang="sass">
.network-topology-graph-container
  height: 75vh
.network-topology-graph-node
  cursor: grab

.network-topology-cursor-grabbing
  cursor: grabbing !important

.network-topology-force-graph
  border: 1px solid #bbb

.network-topology-styled-table
  border-collapse: collapse
  font-size: 0.6em
  font-family: sans-serif
  min-width: 300px
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15)
  border-radius: 1em
  overflow: hidden

.network-topology-styled-table thead tr
  background-color: #0096f4
  color: #ffffff
  text-align: left

.network-topology-styled-table th,
.network-topology-styled-table td
  padding: 0px 7px

.network-topology-styled-table tbody tr
  border-bottom: 1px solid #dddddd

.network-topology-styled-table tbody td:first-of-type
  width: 11.325em

.network-topology-styled-table tbody tr:nth-of-type(even)
  background-color: #f3f3f3
.network-topology-styled-table tbody tr:nth-of-type(odd)
  background-color: rgba(255, 255, 255, 1)

.network-topology-styled-table tbody tr:last-of-type
  border-bottom: 2px solid #0096f4

.network-topology-styled-ul-list
  border: 1px solid #555
  border-radius: 8px
  padding: 3px
  padding-left: 5px !important
  font-size: 0.6em
  background-color: #f3f3f3
  white-space: nowrap
  width: -moz-fit-content
  width: fit-content
  line-height: 1.1
  list-style-type: none

.network-topology-styled-ul-list ul li
  white-space: nowrap
  width: -moz-fit-content
  width: fit-content

.unreachable
  filter: sepia(100%) saturate(300%) brightness(90%) hue-rotate(310deg)
</style>
