class GraphAdjacencyMatrix {
  numberNodes;
  adjacencyMatrix;

  constructor(numberNodes: number) {
    this.numberNodes = numberNodes;

    this.adjacencyMatrix = [];

    for (let i = 0; i < this.numberNodes; i++) {
      this.adjacencyMatrix[i] = new Array(this.numberNodes).fill(0);
    }
  }

  addEdge(node1: number, node2: number) {
    this.adjacencyMatrix[node1][node2] = 1;
    this.adjacencyMatrix[node2][node1] = 1;
  }

  getNeighboors(node: number) {
    return this.adjacencyMatrix[node];
  }

  hasEdge(node1: number, node2: number) {
    if (
      node1 >= 0 &&
      node1 < this.numberNodes &&
      node2 >= 0 &&
      node2 < this.numberNodes
    ) {
      return (
        this.adjacencyMatrix[node1][node2] === 1 &&
        this.adjacencyMatrix[node2][node1] === 1
      );
    }

    return false;
  }

  removeEdge(node1: number, node2: number) {
    if (
      node1 >= 0 &&
      node1 < this.numberNodes &&
      node2 >= 0 &&
      node2 < this.numberNodes
    ) {
      this.adjacencyMatrix[node1][node2] = 0;
      this.adjacencyMatrix[node2][node1] = 0;
    }
  }
}

function minDistance(dist: number[], sptSet: boolean[], V: number) {
  let min = Number.MAX_VALUE;
  let min_index = -1;

  for (let v = 0; v < V; v++) {
    if (sptSet[v] == false && dist[v] <= min) {
      min = dist[v];
      min_index = v;
    }
  }
  return min_index;
}

function dijkstra(graph: number[][], src: number, V: number) {
  const dist = new Array(V);
  const sptSet = new Array(V);

  for (let i = 0; i < V; i++) {
    dist[i] = Number.MAX_VALUE;
    sptSet[i] = false;
  }

  dist[src] = 0;

  for (let count = 0; count < V - 1; count++) {
    const u = minDistance(dist, sptSet, V);

    sptSet[u] = true;

    for (let v = 0; v < V; v++) {
      if (
        !sptSet[v] &&
        graph[u][v] != 0 &&
        dist[u] != Number.MAX_VALUE &&
        dist[u] + graph[u][v] < dist[v]
      ) {
        dist[v] = dist[u] + graph[u][v];
      }
    }
  }
  return dist;
}

export { GraphAdjacencyMatrix, dijkstra };
