
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GraphSettings extends Vue {
  @Prop({ default: 'free', type: String }) private graphType!: string | null;
  @Prop() private showDevicesWithFunction!: string[] | null;
  @Prop({ default: false, type: Boolean }) private sticky!: boolean;
  private images = {
    access_point: require(`@/assets/networkTopologyIcons/access-point.svg`),
    l3_switch: require(`@/assets/networkTopologyIcons/l3-switch.svg`),
    l2_switch: require(`@/assets/networkTopologyIcons/l2-switch-alt.svg`),
    router: require(`@/assets/networkTopologyIcons/router.svg`),
    other: require(`@/assets/networkTopologyIcons/other.svg`),
  };

  private graphTypes = [
    {
      icon: 'mdi-graph mdi-rotate-270',
      value: 'hierarchy-left-to-right',
      description: 'Hierarhičen prikaz glede na funkcionalnost naprave',
      disabled: false,
    },
    {
      icon: 'mdi-dots-circle',
      value: 'free',
      description: 'Prosto grajen graf',
      disabled: false,
    },
    {
      icon: 'mdi-graph',
      value: 'hierarchy-top-down',
      description: 'Hierarhičen prikaz glede na funkcionalnost naprave',
      disabled: false,
    },
    // {
    //   icon: 'mdi-dots-hexagon',
    //   value: 'other',
    //   description: 'Not implemented',
    //   disabled: true,
    // },
  ];

  private deviceTypes = [
    {
      icon: this.images.access_point,
      value: 'access_point',
      description: 'Prikaži dostopovne točke',
    },
    {
      icon: this.images.l2_switch,
      value: 'l2_switch',
      description: 'Prikaži L2 stikala',
    },
    {
      icon: this.images.l3_switch,
      value: 'l3_switch',
      description: 'Prikaži L3 stikala',
    },
    {
      icon: this.images.router,
      value: 'router',
      description: 'Prikaži usmerjevalnike',
    },
    {
      icon: this.images.other,
      value: 'other',
      description: 'Prikaži ostale naprave',
    },
  ];

  private emitDrawer() {
    this.$emit('input', '1');
  }
}
